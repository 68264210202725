<template>
  <treeselect
    v-model="selected"
    :multiple="multiple"
    :options="serviceList"
    :autoLoad-root-options="serviceAutoLoadOpts"
    :disabled="disabled || !serviceGroup"
    placeholder="Subject"
    disable-branch-nodes
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
const DEFAULT_SERVICES = {
  id: 'co',
  label: 'No Services Listed',
  children: [
    {
      id: 0,
      label: '',
    },
  ],
}

export default {
  name: 'FormApptServices',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    franchiseCode: {
      type: String,
      default: null,
    },
    serviceGroup: {
      type: [Number, String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    serviceAutoLoadOpts: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Subject',
    },
  },
  components: {
    Treeselect,
  },
  data: () => ({
    selected: [],
  }),
  computed: {
    ...mapGetters(['activeFranchiseGroupServices']),
    serviceList() {
      const ret = []

      if (!this.activeFranchiseGroupServices.length) {
        ret.push(DEFAULT_SERVICES)
      } else {
        let c = 0
        let categoryIndex
        let serviceIndex

        for (const i in this.activeFranchiseGroupServices) {
          // check if category index exists
          const CAT_ID_PREFIX = 'c'
          categoryIndex = ret.findIndex(
            ({ id }) =>
              id ===
              CAT_ID_PREFIX + this.activeFranchiseGroupServices[i].service.category.id
          )
          // add category if not exists
          if (categoryIndex === -1) {
            ret[c] = {
              id:
                CAT_ID_PREFIX + this.activeFranchiseGroupServices[i].service.category.id,
              label: this.activeFranchiseGroupServices[i].service.category.name,
              children: [],
            }
            categoryIndex = c
            c++
          }
          // check if service index exists
          serviceIndex = ret[categoryIndex].children.findIndex(
            ({ id }) => id === this.activeFranchiseGroupServices[i].service.id
          )
          // add service if not exists
          if (serviceIndex === -1) {
            ret[categoryIndex].children.push({
              id: this.activeFranchiseGroupServices[i].service.id,
              label: `${this.activeFranchiseGroupServices[i].service.category.name}: ${this.activeFranchiseGroupServices[i].service.name}`,
            })
          }
        }
      }

      return ret
    },
  },
  watch: {
    serviceGroup: {
      handler() {
        this.selected = []
        if (!this.serviceGroup) {
          return
        }
        const serviceGroup = this.serviceGroup.split('/')
        if (serviceGroup.length) {
          this.setServiceGroupServices(serviceGroup[serviceGroup.length - 1])
        }
      },
      immediate: true,
    },
    value: {
      handler(value) {
        this.selected = value
      },
      immediate: true,
    },
    selected: {
      handler(value) {
        this.$emit('input', value)
      },
      immediate: true,
    },
    serviceList(val) {
      if (val.length === 0) {
        this.selected = []
      } else if (
        val.length === 1 &&
        val[0].children.length === 1 &&
        val[0].children[0].id
      ) {
        this.selected = [val[0].children[0].id]
      }
    },
  },
  methods: {
    ...mapActions(['getActiveFranchiseGroupServices']),
    async setServiceGroupServices(serviceGroup) {
      await this.getActiveFranchiseGroupServices({
        franchiseCode: this.franchiseCode,
        serviceGroup,
        pagination: false,
      })
    },
  },
}
</script>
