<template>
  <div>
    <!-- SERVICE GROUP -->
    <label class="sr-only" for="service_group">Grade Level</label>
    <b-form-select
      id="service_group"
      :value="value"
      @input="$emit('input', $event)"
      :options="serviceGroupList"
      class="mb-2"
      :required="required"
    >
      <template #first>
        <b-form-select-option :value="null" disabled
          >-- Select a Group --</b-form-select-option
        >
      </template>
    </b-form-select>

    <!-- SERVICE GROUP DETAILS -->
    <transition name="height" mode="out-in">
      <p
        v-if="!hideData && value && serviceGroupData"
        class="animated fadeIn mb-3 border border-info p-2"
      >
        Registration Fee: ${{
          serviceGroupData.regFee ? serviceGroupData.regFee.price : defaultRegFee
        }}
        <br />
        Tutoring Rate: ${{ serviceGroupData.price }}/{{ serviceGroupData.priceDivisor }}
        <b-btn
          size="sm"
          variant="link"
          v-if="modalInfo.content"
          v-b-modal.modalInfoServiceGroup
          class="ml-2"
          ><b-icon icon="info-circle"></b-icon
        ></b-btn>
      </p>
    </transition>

    <b-modal id="modalInfoServiceGroup" :title="modalInfo.title" ok-only>
      <div class="my-4" v-html="modalInfo.content"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const DEFAULT_LIST = [{ value: null, text: 'No Grade Levels Loaded' }]

export default {
  name: 'FormApptServiceGroup',
  props: {
    value: {
      type: String,
    },
    serviceGroupKeyId: {
      type: String,
      default: 'serviceGroup',
    },
    requestServiceGroups: {
      type: Boolean,
      default: false,
    },
    franchiseCode: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    defaultRegFee: {
      type: Number,
      default: 0,
    },
    hideData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serviceGroupData: null,
      modalInfo: {},
    }
  },
  computed: {
    ...mapGetters(['serviceGroups']),
    serviceGroupList() {
      return this.serviceGroups.length
        ? this.serviceGroups
            .map((e) => ({ value: e[this.serviceGroupKeyId], html: e.customName }))
            .sort((a, b) =>
              a.customName > b.customName ? 1 : b.customName > a.customName ? -1 : 0
            )
        : DEFAULT_LIST
    },
    registrationFee() {
      if (this.serviceGroupData.registration_fee !== null) {
        return this.serviceGroupData.registration_fee
      }
      return this.franchise.registration_fee
    },
  },
  watch: {
    franchiseCode() {
      if (this.requestServiceGroups && this.franchiseCode) {
        this.setServiceGroups()
      }
    },
    value() {
      this.setServiceGroupData()
    },
  },
  async mounted() {
    if (this.requestServiceGroups && this.franchiseCode) {
      await this.setServiceGroups()
    }
    this.setServiceGroupData()
  },
  methods: {
    ...mapActions(['getFranchiseServiceGroups']),

    // setServiceGroup (value) {
    //   this.$emit('input', value)
    // },

    /**
     * gets franchise service groups
     */
    async setServiceGroups() {
      await this.getFranchiseServiceGroups(this.franchiseCode)
    },

    /**
     * called when a group is selected. loads serviceGroup details
     */
    async setServiceGroupData() {
      this.serviceGroupData =
        this.serviceGroups.find((sg) => sg[this.serviceGroupKeyId] === this.value) || null

      /* if (!this.serviceGroupData) {
      	console.error('FormApptServiceGroup::setServiceGroupData() invalid service group')
      	return
      } */

      if (this.serviceGroupData) {
        this.$emit('service-group-data', this.serviceGroupData || null)
        this.$emit('input', this.serviceGroupData[this.serviceGroupKeyId] || null)
        this.modalInfo.title = this.serviceGroupData.customName
        if (this.serviceGroupData.description !== '') {
          let serviceGroupDataDescription = this.$options.filters.nl2br(
            this.serviceGroupData.description
          )
          this.modalInfo.content = serviceGroupDataDescription.replace(
            /^\[([^\]]+)\]/g,
            `<span class="text-danger">$1</span>`
          )
        } else {
          this.modalInfo.content = null
        }
      } else {
        this.modalInfo = {}
      }
    },
  },
}
</script>
